import React from 'react';
import logo from './assets/512.png'; // 로고 이미지 경로
import './App.css';
import ImageGallery from './components/ImageGallery';
import YoutubeGallery from './components/YoutubeGallery';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="iTR logo" className="App-logo" />
        Data Science, AI Science
      </header>
      <main>
        <ImageGallery />
        <YoutubeGallery /> 
      </main>
    </div>
  );
}

export default App;
