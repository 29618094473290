import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import './YoutubeGallery.css'; // 스타일 파일을 추가로 불러옵니다.

interface Video {
  no: string | undefined;   // 비디오 번호가 없을 수 있으므로 string | undefined로 설정
  title: string;  // 비디오 제목
  link?: string;  // 비디오 링크 (없을 수 있음)
}

const YoutubeGallery: React.FC = () => {
  const [data, setData] = useState<Video[]>([]); // 데이터를 저장할 상태

  // 컴포넌트가 처음 렌더링될 때 엑셀 파일을 읽어서 데이터를 로드
  useEffect(() => {
    const fetchExcelData = async () => {
      try {
        const response = await fetch('/유뷰브목차.xlsx'); // public 폴더에서 파일을 읽음
        const arrayBuffer = await response.arrayBuffer(); // 파일을 바이너리 데이터로 변환
        const workbook = XLSX.read(arrayBuffer, { type: 'array' }); // 엑셀 파일 파싱
        const sheetName = workbook.SheetNames[0]; // 첫 번째 시트 선택
        const sheet = workbook.Sheets[sheetName];
        const parsedData: Video[] = XLSX.utils.sheet_to_json(sheet); // 시트를 JSON 형태로 변환
        setData(parsedData); // 데이터를 상태에 저장
      } catch (error) {
        console.error('엑셀 데이터를 불러오는 중 오류가 발생했습니다:', error);
      }
    };

    fetchExcelData();
  }, []); // 빈 배열을 전달하여 컴포넌트가 처음 마운트될 때만 실행

  return (
    <div className="youtube-gallery">
      <h1>유뷰브 목차</h1>
      {data.length > 0 ? (
        <Table data={data} />
      ) : (
        <p>엑셀 데이터를 불러오는 중...</p>
      )}
    </div>
  );
};

// 데이터를 테이블로 표시하는 컴포넌트
interface TableProps {
  data: Video[]; // 비디오 데이터를 배열로 받음
}

const Table: React.FC<TableProps> = ({ data }) => {
  return (
    <table className="youtube-table">
      <thead>
        <tr>
          <th>No</th>
          <th>Title</th>
          <th>Link</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => {
          // row.no 값이 문자열로 "0"인지 확인
          const isZero = row.no === "0";

          return (
            <tr key={index} className={isZero ? 'highlight' : ''}>
              <td>{row.no}</td>
              <td>{row.title}</td>
              <td>
                {row.link ? (
                  <a href={row.link} target="_blank" rel="noopener noreferrer">
                    보기
                  </a>
                ) : (
                  'No link available'
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default YoutubeGallery;


/*
import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import './YoutubeGallery.css'; // 스타일 파일을 추가로 불러옵니다.

interface Video {
  no: string;   // 비디오 번호
  title: string;  // 비디오 제목
  link?: string;  // 비디오 링크 (없을 수 있음)
}

const YoutubeGallery: React.FC = () => {
  const [data, setData] = useState<Video[]>([]); // 데이터를 저장할 상태

  // 컴포넌트가 처음 렌더링될 때 엑셀 파일을 읽어서 데이터를 로드
  useEffect(() => {
    const fetchExcelData = async () => {
      try {
        const response = await fetch('/유뷰브목차.xlsx'); // public 폴더에서 파일을 읽음
        const arrayBuffer = await response.arrayBuffer(); // 파일을 바이너리 데이터로 변환
        const workbook = XLSX.read(arrayBuffer, { type: 'array' }); // 엑셀 파일 파싱
        const sheetName = workbook.SheetNames[0]; // 첫 번째 시트 선택
        const sheet = workbook.Sheets[sheetName];
        const parsedData: Video[] = XLSX.utils.sheet_to_json(sheet); // 시트를 JSON 형태로 변환
        setData(parsedData); // 데이터를 상태에 저장
      } catch (error) {
        console.error('엑셀 데이터를 불러오는 중 오류가 발생했습니다:', error);
      }
    };

    fetchExcelData();
  }, []); // 빈 배열을 전달하여 컴포넌트가 처음 마운트될 때만 실행

  return (
    <div className="youtube-gallery">
      <h1>유뷰브 목차</h1>
      {data.length > 0 ? (
        <Table data={data} />
      ) : (
        <p>엑셀 데이터를 불러오는 중...</p>
      )}
    </div>
  );
};

// 데이터를 테이블로 표시하는 컴포넌트
interface TableProps {
  data: Video[]; // 비디오 데이터를 배열로 받음
}

const Table: React.FC<TableProps> = ({ data }) => {
  return (
    <table className="youtube-table">
      <thead>
        <tr>
          <th>No</th>
          <th>Title</th>
          <th>Link</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td>{row.no}</td>
            <td>{row.title}</td>
            <td>
              {row.link ? (
                <a href={row.link} target="_blank" rel="noopener noreferrer">
                  보기
                </a>
              ) : (
                'No link available'
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default YoutubeGallery;
*/
